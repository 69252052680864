//import logo from './logo.svg';
import lsseq from './emojimatch300x650.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          TouchLanguage Info &nbsp;&nbsp;
          <p></p>
        <a
          className="App-link"
          href="https://apps.apple.com/us/app/touchlanguage/id1607759736"
          target="_blank"
          rel="noopener noreferrer"
        >
           TouchLanguage Spanish on the Apple App Store
        </a>
        &nbsp;&nbsp;
        </p>
      </header>
      <body className="App-subHeader">
      <p><a>What is TouchLanguage?</a></p>
        </body>
        <text className="App-text">
        <a> TouchLanguage 1.0 is a powerful new iPhone & iPad Language Learning App:</a>
        <a> Helps you quickly and effectively learn a guidebook of over 1000 of the most used Spanish words & phrases using innovative interactive visual/audio games. </a>
        <a> This guidebook is stand-alone & requires no Internet connection to access its database. Take with you on the go and play and learn quickly and effectively.  
        </a>
        <p></p><a>
        Each of the drills and games has dozens of variations for 1000s of combinations to keep you excited and engaged in your learning.  
        </a>
        <p></p>
        <p></p>
          <p></p>
          <p></p>
        </text>
        <body className="App-subHeader">
        Do drills and optimize your learning, by touching the correct images and words:  
        </body>
        <text className="App-text">
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <img src={lsseq} alt="TouchLanguage Sequencer" width={300} height={650} style={{ alignSelf: 'center' }} />
          <p></p>
          <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        
        </text>

        <div className='privacy'>
      <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
      <h1>TouchLanguage Privacy Policy</h1>
      <body className="App-subHeader">
      <p><a>We do not collect any data.</a></p>
        </body>
        <text className="App-text">
        <a> Privacy Policy
This policy applies to all information collected or submitted on TouchLanguage’s website and our apps for iPhone and any other devices and platforms.
Information we collect. 
As of TouchLanguage Version 1.06 we do not collect or store any information from either the App or the Website.  

Technical basics
At this point on our development roadmap, we have no intention of collecting any information.  As of TouchLanguage Version 1.06 we do not use notifications, user logins, or upload any data to our servers whatsoever.   
TouchLanguage uses the 3rd Party Open Source AudioKit project.   To the best of our knowledge the AudioKit Library collects no data.

California Online Privacy Protection Act Compliance
We comply with the California Online Privacy Protection Act. We therefore will not distribute your personal information to outside parties without your consent.

Children’s Online Privacy Protection Act Compliance
We never collect or maintain information at our website from those we actually know are under 13, and no part of our website is structured to attract anyone under 13.

Information for European Union Customers
By using TouchLanguage and providing your information, you authorize us to collect, use, and store your information outside of the European Union.

International Transfers of Information
Information may be processed, stored, and used outside of the country in which you are located. Data privacy laws vary across jurisdictions, and different laws may be applicable to your data depending on where it is processed, stored, or used.
<p></p>
Your Consent:
By using our site or apps, you consent to our privacy policy.
Contacting Us
<p></p>
If you have questions regarding this privacy policy, you may email touchlanguage@gmail.com
</a>
        <p></p>
        <p></p>
       
        <p></p>
        <p></p>
        <h1>TouchLanguage  iOS App Terms of Use</h1>
        
        <p></p>
					
        The terms of use for the TouchLanguage mobile application for iOS (“iOS App Terms of Use”) is a legally binding agreement. Please read this document carefully. By clicking “I Agree,” or installing or using the TouchLanguage mobile application for iOS software and/or any updates to such software (collectively, “App”) provided by 7hillsStudios and/or its affiliates (collectively, “TouchLanguage”), you Agree to the following terms as an authorized User of the entity with which you are employed, affiliated, or associated (“Customer”), which has concluded a subscription agreement for a TouchLanguage pro features (“Agreement”).
        <p></p>                                
        1. GENERAL TERMS
        <p></p>                                
        1.1  You represent that you are an authorized User under the Agreement. If you do not qualify as an authorized User, then you may download the App, but will not be able to access the TouchLanguage full feature set. 						 							
        <p></p>
        1.2  The iOS App Terms of Use is governed by the terms of the Agreement and any amendment thereto or additional purchase thereunder. The iOS App Terms of Use adjusts and/or supplements certain terms of the Agreement, solely with respect to the App. Capitalized terms used but not defined in the iOS App Terms of Use have the meanings given to them in the Agreement. 												 							
        <p></p>
        1.3  The App contains a feature that allows a Customer User to purchase a subscription to TouchLanguage pro features that are not available without subscription. 						 							
        <p></p>
        1.4  If Customer User uses the App, Customer Data will not be transferred to and from the respective technical infrastructure systems that support TouchLanguage, including servers administered by Amazon Web Services, Inc. (“AWS”). Additional information regarding these technical infrastructure systems and the rules that apply to such transfers is available in the Agreement. 						 							
        <p></p>
        1.5  Apple Inc. may, at any time and without notice, restrict, interrupt, or prevent use of the App, or delete the App from your or Customer's Apple device(s), or require TouchLanguage to do any of the foregoing, without entitling Customer or you to any refund, credit, or other compensation from TouchLanguage or any third party (including, but not limited to, Apple Inc. or your network connectivity provider). 												 							
        <p></p>
        1.6  The iOS App Terms of Use is located at: https://www.apple.com/legal/internet-services/itunes/dev/stdeula/. It is effective between you and TouchLanguage as of the date you first download, install, or use the App, whichever occurs earliest and can be accessed and downloaded at: https://www.TouchLanguage.pro/. TouchLanguage may update and change any part or all of the iOS App Terms of Use. 
        The updated version will be posted publicly on TouchLanguage’s website at the above link and will be effective and binding on the day after posting. 
        TouchLanguage encourages Customer and Users to review the iOS App Terms of Use periodically, as you are responsible for reading and complying with any amended version(s). If Customer or User objects to any such changes, Customer’s or User’s sole recourse shall be to cease using the App. Continued use of the App following the relevant Version Effective Date shall indicate Customer's and User’s acknowledgement of such changes and agreement to be bound by the updated iOS App Terms of Use.
        <p></p>
        1.7 You acknowledge and agree to TouchLanguage’s Privacy Policy, which can be accessed and downloaded at: https://www.TouchLanguage.pro/ 
        <p></p>                                
        2. LICENSE TO USE THE APP
        <p></p>                            
        2.1  The App, including software embedded in the App, is licensed, not sold, to Customer by TouchLanguage only under the terms of the Agreement and TouchLanguage reserves all rights not expressly granted to Customer. The App contains some open source software libraries, the license terms of which are available on written request to TouchLanguage. TouchLanguage and its licensors retain ownership of the software contained in the App. 						 							
        <p></p>
        2.2  Unless otherwise specified in the Agreement and subject to payment of the Subscription Fee and any other undisputed amounts due, TouchLanguage grants to Customer, and subsequently you as User, a limited, non-exclusive, non-assignable, non-transferable, renewable, and revocable license during the applicable Term, to access and use the App on any Supported Device and on no other devices, exclusively for Customer’s internal business purposes, as set out in the Agreement. 
        The license granted to User and Customer for the App is a non-transferable license to use the App on any iOS devices that User owns or controls and as permitted by Apple (“Supported Devices”). 						 							
        <p></p>
        2.3  Such license permits you to use Intellectual Property Rights and Confidential Information included in the App and to make copies of software or other information strictly as necessary to download, install, and use the App. Such license shall not be construed to mean, by inference or otherwise, that Customer has any right to access or obtain any source code for the App. 										 							
        <p></p>
        2.4  Without limiting the generality of anything herein, you acknowledge and agree that the App may collect use or device data for the purposes of providing services or functions that are relevant to use of the App. 						
        <p></p>                                                 
        3. APPLE TERMS
        <p></p>                        
        3.1  TouchLanguage, and not Apple, is solely responsible for the App and the license granted herein. Apple has no obligation to provide maintenance and support for the App. 						
        <p></p>
        3.2  User’s and Customer's use of the App must comply with the Usage Rules set forth in the Apple App Store Terms of Use, which can be accessed and downloaded at: https://www.apple.com/legal/internet-services/itunes/us/terms.html.				
        <p></p>
        3.3  The App is not covered by any service level agreement between Customer and TouchLanguage. Nevertheless, TouchLanguage will aim to provide customer support for the App to the same standard as set out in the Agreement.
        <p></p>
        3.4  In order to respond to FAQs and help its customers to resolve common problems without needing	 direct assistance from Support, TouchLanguage maintains the Knowledge Base on the TouchLanguage website (TouchLanguage.pro). If your question is not resolved via the Knowledge Base, the TouchLanguage help desk can be contacted by email anytime via touchlanguage@gmail.com				
        <p></p>                                                     
        3.5  Apple is not responsible for addressing, investigating, defending, settling, or discharging any claim brought by Customer or any third party for allegations relating to the App, or your or Customer's possession and/or use of the App, including but not limited to: (a) product liability; (b) any failure of the App to comply with applicable legal or regulatory requirements; (c) consumer protection or similar legislation; or (d) infringement of third party intellectual property rights. 						 							
        <p></p>
        3.6  As a User, you represent and warrant that: (a) the App will not be downloaded in, used in, or transported to a country that is subject to a U.S. Government embargo or EU government sanctions, or that has been designated by the U.S. Government or any EU government as a "terrorist-supporting" country or similar; and (b) neither Customer nor User is listed on any U.S. Government or EU government list of prohibited or restricted parties. 						 							
        <p></p>
        3.7  TouchLanguage’s address is 1108 13th Ave Seattle WA, USA. Any claims regarding the App may be submitted via email to touchlanguage@gmail.com 						 							
        <p></p>
        3.8  Notwithstanding anything to the contrary in the Agreement, Apple and its subsidiaries are third- party beneficiaries of the iOS App Terms of Use, and have the right (and shall be deemed to have accepted the right) to enforce the iOS App Terms of Use against you and Customer. 												 							
        <p></p>
        3.9  To the maximum extent permitted by applicable law, Apple will have no warranty obligation whatsoever with respect to the App, and will not be liable for any claims, losses, liabilities, damages, costs, or expenses attributable to any failure of the App to conform to any warranty. Apple shall not be required to provide a refund to you or Customer under any circumstances. 						
        <p></p>                                                
        4. TERM AND TERMINATION
        <p></p>                             
        4.1  TouchLanguage may terminate access to the App at any time upon thirty (30) days' notice to Customer without cause, or immediately upon notice to Customer if: any third party (including Apple or your network connectivity provider) restricts, prevents, or ceases to authorize the installation or use of the App on your Supported Device or over your network. In addition, the iOS App Terms of Use shall terminate immediately and automatically upon the termination or expiration of Customer's TouchLanguage subscription for whatever reason. Upon any such termination or expiration, Customer and its Users will no longer be permitted to use the App, and will delete or destroy all copies of the App in its possession, including on all devices of its Users. 												 							4.2  Termination of the iOS App Terms of Use shall not entitle Customer to any refund, credit, or other compensation from TouchLanguage under the Agreement or any other agreement or from any third party.  						
                                                         
                                
                            
                    
                    
                    
                    
        <p></p>
        <p></p>
        <p></p>
        </text>
       
    </div>

    </div>
    
    
  );
}

export default App;
